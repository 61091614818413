import React from 'react';
import { useLocation } from 'react-router-dom';

import { Container, Layout, Logo } from './styles';

const Header: React.FC = () => {
  const location = useLocation();

  return (
    <Container>
      <Layout>
        <Logo />

        <p>
          Link de pagamento
          {location.pathname.includes('pagamento/ps') && (
            <strong>Pag Seguro</strong>
          )}
          {location.pathname.includes('pagamento/mp') && (
            <strong>Mercado Pago</strong>
          )}
          {location.pathname.includes('pagamento/pix') && (
            <strong>Pix QR Code</strong>
          )}
          {location.pathname.includes('/') && <></>}
        </p>
      </Layout>
    </Container>
  );
};
export default Header;
