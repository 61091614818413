import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import LogoSucess from '../../assets/logoSucess.svg';
import PaymentReview from '../../assets/PaymentReview.svg';
import Cancel from '../../assets/Cancel.svg';

import MessagePage from '../../components/MessagePage';

interface LocationProps {
  responseCode?: number;
  SDK?: string;
}

const Success: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<LocationProps>();
  const { responseCode, SDK } = state || { responseCode: 999 };

  return (
    <>
      {/* Sucesso */}
      {responseCode === 201 && (
        <MessagePage
          image={LogoSucess}
          title="Sucesso! Obrigado por comprar na Multicoisas"
          message="O seu pedido foi efetivado com sucesso e o pagamento foi
          confirmado. Em breve, seu produto estará em suas mãos!"
        />
      )}

      {/* Análise */}
      {responseCode === 202 && (
        <MessagePage
          image={PaymentReview}
          title="Pagamento em análise!"
          message={`O ${SDK} está processando seu pagamento e em até 2 dias
          úteis informaremos por e-mail se foi aprovado ou se precisamos de
          mais informações.`}
        />
      )}

      {/* Pagamento Recusado */}
      {responseCode === 500 && (
        <MessagePage
          image={Cancel}
          title="Pagamento recusado!"
          message="Infelizmente a compra não pode ser efetuada, entre em contato com o vendedor e tente outra forma de pagamento."
        />
      )}

      {/* Caso o usuário tenha acessado a página sem ter feito a compra */}
      {responseCode === 999 && history.push('/invalid-token')}
    </>
  );
};

export default Success;
