import React from 'react';
import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <span>
        © Copyright 2020 - Multicoisas Franquias LTDA. Todos os direitos
        reservados. CNPJ: 00.992.115/0001-00
      </span>
    </Container>
  );
};

export default Footer;
