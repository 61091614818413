import React, { useEffect, useRef, SelectHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import { StyledReactSelect, Error } from './styles';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
}

const Select: React.FC<Props> = ({ name, ...rest }) => {
  const selectRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <StyledReactSelect
        onFocus={clearError}
        defaultValue={defaultValue}
        ref={selectRef}
        {...rest}
        isErrored={!!error}
      />

      {error && <Error>{error}</Error>}
    </div>
  );
};

export default Select;
