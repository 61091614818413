import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex: 1;

  background-color: #f9fafc;
  padding: 30px 0;

  justify-content: center;
  align-items: center;
`;

export const Layout = styled.div`
  @media (min-width: 700px) {
    max-width: 1000px;
    width: 1000px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

    & > section {
      flex: 0 50%;
    }
  }
`;

export const QrCodeSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 32px;

  flex-direction: column;

  h3 {
    margin: 20px 0px 10px 10px;
    align-self: start;

    font-size: 14px;
    font-weight: 700;
    color: #595857;
  }
`;

export const QrCodeImage = styled.div`
  padding: 25px;

  background: linear-gradient(to right, black 6px, transparent 6px) 0 0,
    linear-gradient(to right, black 6px, transparent 6px) 0 100%,
    linear-gradient(to left, black 6px, transparent 6px) 100% 0,
    linear-gradient(to left, black 6px, transparent 6px) 100% 100%,
    linear-gradient(to bottom, black 6px, transparent 6px) 0 0,
    linear-gradient(to bottom, black 6px, transparent 6px) 100% 0,
    linear-gradient(to top, black 6px, transparent 6px) 0 100%,
    linear-gradient(to top, black 6px, transparent 6px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 30px 30px;

  img {
    width: 100%;
  }

  @media (min-width: 700px) {
    margin-top: 42px;
  }
`;

const TextAppear = keyframes`
  from {
    font-weight: 0;
    font-size: 0px;
  }
  to {
    font-weight: 700;
    font-size: 12px;
  }
`;

export const WaitingPayment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 12px;
  padding: 0px 16px;

  background-color: #ffeacd;
  border-radius: 4px;

  h4 {
    font-weight: 700;
    font-size: 12px;
    color: #ff9f1a;
    animation: ${TextAppear} 0.5s linear;
  }
`;

const waitingPaymentKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderAppear = keyframes`
  0% {
    height: 0px;
    visibility: hidden;
    opacity: 0;
  }
  50% {
    height: 32px;
    visibility: hidden;
    opacity: 0;
  }
  100% {
    height: 32px;
    visibility: visible;
    opacity: 1;
  }
`;

export const WaitingPaymentAnimation = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;

  animation: ${LoaderAppear} 1s linear;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #ff9f1a transparent #ff9f1a transparent;
    animation: ${waitingPaymentKeyframes} 1.2s linear infinite;
  }
`;

export const PixCodeInput = styled.div`
  width: 100%;
  height: 40px;

  display: flex;

  input:disabled {
    flex: 1;

    height: 40px;
    padding-left: 16px;
    border: 1.5px solid #b8b6b4;
    border-right: 0px;
    border-radius: 4px 0 0 4px;

    background-color: #ebeced;
    color: #858482;
  }

  button {
    background: #ff9f1a;
    padding: 8px 32px;

    border: 0;
    border-radius: 0 4px 4px 0;

    color: #f9fafc;
    font-weight: 700;
    font-size: 16px;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f58f00;
    }
  }
`;

const copySuccessAnimation = keyframes`
  from{
    font-size: 0px;
    padding: 0px 16px;
    line-height: 0px;
  }
  to {
    font-size: 12px;
    padding: 6px 16px;
    line-height: 17px;
  }
`;

export const CopySuccessBox = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 6px 16px;

  background-color: #d9ffe2;
  border-radius: 4px;

  color: #0acc3b;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  animation: ${copySuccessAnimation} 0.5s normal;
`;
