import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

import {
  isTelephoneValid,
  isExpiryDateValid,
  isBirthDateValid,
} from '../utils/formValidationFunctions';

interface PagSeguroHook {
  validationSchema: Yup.AnyObjectSchema;
}

const usePagSeguro = (): PagSeguroHook => {
  const validationSchema = Yup.object().shape({
    cardholderName: Yup.string().required('O nome é obrigatório'),

    creditCardNumber: Yup.number()
      .typeError('Insira um número de cartão de crédito')
      .required('O cartão de crédito é obrigatório'),

    expiryDate: Yup.string()
      .required('A data é obrigatória')
      .default('__/____')
      .test('Data de validade inválida', 'Data de validade inválida', value =>
        isExpiryDateValid(value),
      ),

    securityCode: Yup.number()
      .typeError('Insira o código de segurança')
      .required('O código de segurança é obrigatório'),

    cpf: Yup.string()
      .required('O CPF é obrigatório')
      .default('99999999999')
      .test('CPF inválido', 'CPF inválido', value => cpf.isValid(value)),

    cardHolderBirthDate: Yup.string()
      .required('A data de nascimento é obrigatória')
      .default('99/99/9999')
      .test(
        'Data de nascimento inválida',
        'Data de nascimento inválida',
        value => isBirthDateValid(value),
      ),

    telephone: Yup.string()
      .required('O telefone é obrigatório')
      .default('(__)_____-____')
      .test('Telefone inválido', 'Telefone inválido', value =>
        isTelephoneValid(value),
      ),

    email: Yup.string().required('O Email é obrigatório'),

    installments: Yup.number()
      .typeError('Selecione uma opção de parcela')
      .required(),
  });

  return { validationSchema };
};

export default usePagSeguro;
