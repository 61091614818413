import styled from 'styled-components';

import logo from '../../assets/logo.png';
import logoMobile from '../../assets/logoMobile.png';

export const Container = styled.header`
  display: flex;
  justify-content: center;
  background-color: #f9fafc;

  width: 100%;
  height: 60px;
  z-index: 100;

  box-shadow: 0px -17px 191px rgba(69, 69, 69, 0.11),
    0px -6.20528px 69.7181px rgba(69, 69, 69, 0.075898),
    0px -3.01255px 33.8469px rgba(69, 69, 69, 0.061192),
    0px -1.47681px 16.5924px rgba(69, 69, 69, 0.048808),
    0px -0.583932px 6.56065px rgba(69, 69, 69, 0.034102);

  @media (min-width: 700px) {
    height: 80px;
  }
`;

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 14px 16px;

  @media (min-width: 700px) {
    padding: 14px 25px;
    max-width: 1000px;
  }

  @media (min-width: 1100px) {
    padding: 14px 0px;
  }

  p {
    color: #9e9d9b;
    font-size: 12px;
    line-height: 17px;

    @media (min-width: 700px) {
      font-size: 14px;
      line-height: 21px;
    }

    strong {
      color: #454443;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;

      margin-left: 5px;

      @media (min-width: 700px) {
        font-size: 14px;
        line-height: 21px;
        margin-left: 10px;
      }
    }
  }
`;

export const Logo = styled.div`
  background: url(${logoMobile}) no-repeat;
  width: 36px;
  height: 29px;
  margin-right: 5px;

  @media (min-width: 700px) {
    background: url(${logo}) no-repeat;
    width: 161px;
    height: 32px;
  }
`;
