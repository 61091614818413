import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
  isFocused: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 40px;
  padding: 8px 16px;

  display: flex;

  border: 1px solid #d8d9da;
  border-radius: 4px;

  background: #fff;

  ${props =>
    props.isErrored &&
    css`
      border-color: #f23524;
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: #ff9f1a;
    `}

  & + div {
    margin-top: 8px;

    @media (min-width: 700px) {
      margin-top: 24px;
    }
  }

  input {
    width: 100%;
    height: 21px;
    color: #595857;
    line-height: 21px;

    border: 0;

    &::placeholder {
      color: #9e9d9b;
    }
  }
`;
