/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useCallback, MouseEvent } from 'react';
import { useSpring, animated } from 'react-spring';

import {
  Background,
  ModalWrapper,
  ModalHeaderArea,
  Logo,
  CloseModalButton,
  ModalContentArea,
  ModalButtonArea,
} from './styles';

interface ModalProps {
  showModal: boolean;
  setShowModal: Function;
}

const Modal: React.FC<ModalProps> = ({ showModal, setShowModal }) => {
  const modalRef = useRef() as React.RefObject<HTMLDivElement>;

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = (e: MouseEvent) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper>
              <ModalHeaderArea>
                <Logo />
                <CloseModalButton
                  aria-label="Fechar"
                  onClick={() => setShowModal((prev: any) => !prev)}
                />
              </ModalHeaderArea>
              <ModalContentArea>
                <p>
                  Prezado cliente, a Multicoisas{' '}
                  <strong>
                    não solicita pagamentos extras em máquinas de cartão de
                    crédito/débito
                  </strong>{' '}
                  no momento da entrega. Fique atento! Em caso de dúvidas entre
                  em contato com a gente!
                </p>
              </ModalContentArea>
              <ModalButtonArea>
                <button
                  type="button"
                  onClick={() => setShowModal((prev: any) => !prev)}
                >
                  Entendido
                </button>
              </ModalButtonArea>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};

export default Modal;
