/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Socket } from 'socket.io-client';

import Modal from '../../components/Modal';
import OrderLayout, { Product } from '../../components/OrderLayout';
import { QRCodeContentLoader } from '../../components/ContentLoader';
import useCopyToClipboard from '../../hooks/copyToClipboard';
import useWidthSize from '../../hooks/widthSize';
import socketConfig from '../../config/socketConfig';

import api from '../../services/api';

import {
  Container,
  Layout,
  QrCodeSection,
  QrCodeImage,
  PixCodeInput,
  CopySuccessBox,
  WaitingPayment,
  WaitingPaymentAnimation,
} from './styles';

interface ParamsProps {
  token: string;
}

interface PaymentInfoData {
  qr_code: string;
  formattedTotal: number;
  items: Product[];
  carrinhoId: string;
}

const PaymentPix: React.FC = () => {
  const { token } = useParams<ParamsProps>();
  const { widthSize } = useWidthSize();
  const {
    inputRef,
    copySuccessMessage,
    copyToClipboard,
  } = useCopyToClipboard();

  const history = useHistory();
  const [paymentInfo, setPaymentInfo] = useState({} as PaymentInfoData);
  const [socketReady, setSocketReady] = useState(false);
  const [socket, setSocket] = useState<Socket>();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    async function tokenValidator() {
      try {
        const response = await api.get(`payment/pix/checkurl?token=${token}`);

        const {
          formattedTotal,
          items,
          QR_CODE,
          CARRINHOID,
        } = response.data.data;

        setPaymentInfo({
          formattedTotal,
          items,
          qr_code: QR_CODE,
          carrinhoId: CARRINHOID,
        });
      } catch (err) {
        history.push('/invalid-token');
      }
    }
    tokenValidator();
    setSocket(socketConfig);
  }, [history, token]);

  useEffect(() => {
    if (socket) {
      const paymentSuccessCallback = () => {
        history.push('/pagamento', {
          responseCode: 201,
        });
      };

      setTimeout(() => {
        if (socket.connected) {
          socket.on(
            `${paymentInfo.carrinhoId}_APPROVED`,
            paymentSuccessCallback,
          );
          setSocketReady(socket.connected);
        }
      }, 5000);
    }
  }, [history, paymentInfo.carrinhoId, socket]);

  return (
    <Container>
      <Layout>
        <QrCodeSection>
          <QrCodeImage>
            {paymentInfo.qr_code ? (
              <QRCode
                value={paymentInfo.qr_code}
                renderAs="svg"
                size={widthSize > 700 ? 350 : 192}
              />
            ) : (
              <QRCodeContentLoader
                isDesktop={widthSize > 700 ? '350' : '192'}
              />
            )}
          </QrCodeImage>

          {socketReady && (
            <WaitingPayment>
              <WaitingPaymentAnimation />
              <h4>Aguardando pagamento...</h4>
            </WaitingPayment>
          )}

          <h3>Chave Pix copia e cola </h3>
          <PixCodeInput>
            <input
              ref={inputRef}
              value={paymentInfo.qr_code || 'Obtendo chave Pix...'}
              type="text"
              disabled
            />

            <button type="button" onClick={copyToClipboard}>
              Copiar
            </button>
          </PixCodeInput>

          {copySuccessMessage && (
            <CopySuccessBox>{copySuccessMessage}</CopySuccessBox>
          )}
        </QrCodeSection>

        <OrderLayout
          items={paymentInfo.items}
          total={paymentInfo.formattedTotal}
        />
      </Layout>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default PaymentPix;
