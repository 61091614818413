export function isExpiryDateValid(value: string): boolean {
  const [month, year] = value.split('/');

  const actualDate = new Date();

  if (value.includes('_')) return false;

  if (Number(month) <= 0 || Number(month) > 12) return false;

  if (Number(year) < actualDate.getFullYear()) return false;

  if (
    Number(year) === Number(actualDate.getFullYear()) &&
    Number(month) < actualDate.getMonth()
  )
    return false;

  return true;
}

export function isBirthDateValid(value: string): boolean {
  const [day, month, year] = value.split('/');

  const actualDate = new Date();

  if (value.includes('_')) return false;

  if (
    Number(year) > actualDate.getFullYear() - 10 ||
    Number(year) < actualDate.getFullYear() - 150
  )
    return false;

  if (Number(month) < 1 || Number(month) > 12) return false;

  if (Number(day) < 1 || Number(day) > 31) return false;

  return true;
}

export function isTelephoneValid(value: string): boolean {
  const telephone = value.substring(0, 13);

  if (telephone.includes('_')) return false;

  return true;
}
