import styled, { css, keyframes } from 'styled-components';

interface LayoutProps {
  isHorizontal: boolean;
}

export const Container = styled.div`
  flex: 1;
  background-color: #f9fafc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LayoutAnimation = keyframes`
    from{
        opacity: 0;
        transform: translate(0, -25px);
    }
    to{
        opacity: 1;
        transform: translate(0, 0);
    }

`;

export const Layout = styled.div<LayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;

  max-width: 1008px;
  padding: 20px;

  animation: ${LayoutAnimation} 1s normal;

  @media (min-width: 700px) {
    flex-direction: row-reverse;
  }

  ${props =>
    props.isHorizontal &&
    css`
      @media (min-width: 700px) {
        flex-direction: column;

        section {
          h1 {
            margin-top: 20px;
          }
        }
      }
    `}

  section {
    flex: 0 50%;
  }
`;

export const MessageSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: #454443;

    margin-top: 20px;

    @media (min-width: 700px) {
      font-weight: bold;
      font-size: 38px;
      line-height: 47px;

      margin-top: 0px;
    }
  }
  h4 {
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    color: #454443;

    @media (min-width: 700px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

export const ImageSection = styled.section`
  display: flex;
  max-width: 575px;
  padding: 0px 15px;

  justify-content: center;

  img {
    width: 100%;
  }
`;
