import styled, { keyframes } from 'styled-components';

const tooltipAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(0px, 20px);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
`;

export const Container = styled.div`
  position: relative;

  &:hover > span {
    display: block;
    visibility: visible;

    animation: ${tooltipAnimation} 0.5s normal;
  }
`;

export const Error = styled.span`
  display: none;
  visibility: hidden;
  width: 110px;
  background-color: #ffd8db;
  border-radius: 6px;
  padding: 5px;

  color: #ff4050;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -95px;

  @media (min-width: 1050px) {
    margin-left: -55px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 35px;
    border-width: 5px;
    border-style: solid;
    border-color: #ffd8db transparent transparent transparent;

    @media (min-width: 1050px) {
      margin-left: -5px;
    }
  }
`;
