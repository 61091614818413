/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';

import api from '../../services/api';
import useMercadoPago from '../../hooks/mercadoPago';

import Modal from '../../components/Modal';
import Input, { InputMask } from '../../components/Input';
import Select from '../../components/Select';
import OrderLayout, { Product } from '../../components/OrderLayout';

import creditCard from '../../assets/creditCard.svg';

import getValidationError from '../../utils/getValidationError';

import {
  Container,
  Layout,
  PaymentLayout,
  FormRow,
  SubmitButton,
  Error,
  LoadingBars,
} from './styles';

declare global {
  interface Window {
    Mercadopago: any;
  }
}

interface ParamsProps {
  token: string;
}

interface InstallmentsOptionsData {
  text: string;
  value: string;
}

interface PaymentInfoData {
  formattedTotal: number;
  total: number;
  publicKey: string;
  items: Product[];
  carrinhoId: number;
  seg_usuario: number;
}

const PagamentoMP: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { token } = useParams<ParamsProps>();
  const history = useHistory();
  const { validationSchema } = useMercadoPago();

  const [showModal, setShowModal] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState({} as PaymentInfoData);
  const [inputPaymentMethodId, setInputPaymentMethodId] = useState('');
  const [cardExpirationMonth, setCardExpirationMonth] = useState('');
  const [cardExpirationYear, setCardExpirationYear] = useState('');
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [error, setError] = useState('');
  const [doSubmit, setDoSubmit] = useState(false);
  const [inputPaymentToken, setInputPaymentToken] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [installmentsOptions, setInstallmentsOptions] = useState<
    InstallmentsOptionsData[]
  >([
    {
      text: 'Número de parcelas',
      value: 'initialValue',
    },
  ]);

  useEffect(() => {
    async function tokenValidator() {
      try {
        const result = await api.get(`/payment/checkurl?token=${token}`);

        const {
          TOTAL,
          formattedTotal,
          PUBLIC_KEY,
          items,
          CARRINHOID,
          SEG_USUARIOID,
        } = result.data.data;

        setPaymentInfo({
          total: TOTAL,
          formattedTotal,
          publicKey: PUBLIC_KEY,
          items,
          carrinhoId: CARRINHOID,
          seg_usuario: SEG_USUARIOID,
        });
      } catch (err) {
        history.push('/invalid-token');
      }
    }
    tokenValidator();
  }, [history, token]);

  /*
    Configurações iniciais da SDK do Mercado Pago
  */
  const MercadoPago = useCallback(() => {
    if (paymentInfo.publicKey !== undefined) {
      window.Mercadopago.setPublishableKey(`${paymentInfo.publicKey}`);
      window.Mercadopago.getIdentificationTypes();
    }
  }, [paymentInfo.publicKey]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
    script.async = true;
    script.onload = () => MercadoPago();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [MercadoPago]);

  const getInstallments = useCallback(
    paymentMethodId => {
      window.Mercadopago.getInstallments(
        {
          payment_method_id: paymentMethodId,
          amount: paymentInfo.total,
        },
        function creatingInstallments(status: any, response: any) {
          if (status === 200) {
            setInstallmentsOptions([]);

            response[0].payer_costs.forEach((installment: any, index: any) => {
              if (
                index >= 4 ||
                (index > 0 && installment.installment_amount < 10)
              ) {
                return;
              }

              const installmentOption = {
                text: installment.recommended_message,
                value: installment.installments,
              };
              setInstallmentsOptions(previousOptions => [
                ...previousOptions,
                installmentOption,
              ]);
            });
          } else {
            formRef.current?.setFieldError(
              'installments',
              `Houve um erro na obtenção de parcelas: ${response.message}`,
            );
          }
        },
      );
    },
    [paymentInfo.total],
  );

  const setPaymentMethod = useCallback(
    (status, response) => {
      if (status === 200) {
        const paymentMethodId = response[0].id;
        setInputPaymentMethodId(paymentMethodId);
        getInstallments(paymentMethodId);
      } else {
        setIsSubmiting(false);
        // Apenas seta erro se o cartão for inválido ou a Public_Key não for válida
        setError('Este cartão de crédito é inválido.');
      }
    },
    [getInstallments],
  );

  const guessPaymentMethod = useCallback(
    event => {
      const cardMasked = event.target.value;
      const cardNumber = cardMasked.replace(/\D/g, '');
      if (cardNumber.length >= 6) {
        const bin = cardNumber.substring(0, 6);

        window.Mercadopago.getPaymentMethod(
          {
            bin,
          },
          setPaymentMethod,
        );
      }
    },
    [setPaymentMethod],
  );

  /*
    Envio do formulário
  */
  const formSubmit = useCallback(
    async (cardToken, telephone) => {
      try {
        const paymentData = {
          amount: paymentInfo.total,
          carrinhoid: paymentInfo.carrinhoId,
          email: formRef.current?.getFieldValue('email'),
          installments: Number(formRef.current?.getFieldValue('installments')),
          payment_method_id: inputPaymentMethodId,
          seg_usuario: paymentInfo.seg_usuario,
          telefone: telephone,
          token: cardToken,
        };

        const { status } = await api.post('payment', paymentData);

        history.push('/pagamento', {
          responseCode: status,
          SDK: 'Mercado Pago',
        });
      } catch (err) {
        history.push('/pagamento', {
          responseCode: 500,
        });
      }
    },
    [
      history,
      inputPaymentMethodId,
      paymentInfo.carrinhoId,
      paymentInfo.seg_usuario,
      paymentInfo.total,
    ],
  );

  /*
    Criação do token do cartão de crédito do Mercado Pago
  */
  const doPay = useCallback(
    // eslint-disable-next-line consistent-return
    event => {
      const cardMasked = formRef.current?.getFieldValue('creditCardNumber');
      setCreditCardNumber(cardMasked.replace(/\D/g, ''));

      const expiryDate = formRef.current?.getFieldValue('expiryDate');

      setCardExpirationMonth(expiryDate.slice(0, 2));
      setCardExpirationYear(expiryDate.slice(3, 7));

      const telephoneInput: string = formRef.current?.getFieldValue(
        'telephone',
      );

      let telephone = telephoneInput;

      if (telephoneInput[13] === '_') {
        telephone = telephoneInput.slice(0, 13);
      }

      if (!doSubmit) {
        const form = event.target;

        window.Mercadopago.createToken(
          form,
          function setCardTokenAndPay(status: any, response: any) {
            if (status !== 200 && status !== 201) {
              setIsSubmiting(false);
              setError('Por favor, preencha os campos corretamente');
            } else {
              setInputPaymentToken(response.id);
              setDoSubmit(true);
              formSubmit(response.id, telephone);
            }
          },
        );

        return false;
      }
    },
    [doSubmit, formSubmit],
  );

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (data: object, { reset }, event) => {
      try {
        setIsSubmiting(true);
        formRef.current?.setErrors({});
        setError('');

        await validationSchema.validate(data, { abortEarly: false });

        doPay(event);
      } catch (err) {
        setIsSubmiting(false);
        if (err instanceof Yup.ValidationError) {
          const validationErrors = getValidationError(err);
          formRef.current?.setErrors(validationErrors);
        }
      }
    },
    [doPay, validationSchema],
  );

  return (
    <Container>
      <Layout>
        <PaymentLayout>
          {error && <Error>Erro no formulário: {error}</Error>}
          <h2>Formulário de Pagamento</h2>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="cardholderName"
              id="cardholderName"
              placeholder="Nome como está no cartão"
              type="text"
              data-checkout="cardholderName"
            />
            <InputMask
              mask="9999 9999 9999 9999"
              name="creditCardNumber"
              placeholder="Número do cartão"
              type="text"
              onInput={guessPaymentMethod}
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />
            <FormRow>
              <InputMask
                mask="99/9999"
                name="expiryDate"
                placeholder="Validade cartão"
                type="text"
                onSelect={e => e.preventDefault()}
                onPaste={e => e.preventDefault()}
                onCopy={e => e.preventDefault()}
                onCut={e => e.preventDefault()}
                onDrag={e => e.preventDefault()}
                onDrop={e => e.preventDefault()}
                autoComplete="off"
              />
              <InputMask
                mask="999"
                name="securityCode"
                placeholder="Cód. segurança"
                type="text"
                id="securityCode"
                data-checkout="securityCode"
                onSelect={e => e.preventDefault()}
                onPaste={e => e.preventDefault()}
                onCopy={e => e.preventDefault()}
                onCut={e => e.preventDefault()}
                onDrag={e => e.preventDefault()}
                onDrop={e => e.preventDefault()}
                autoComplete="off"
              />
              <img src={creditCard} alt="Localidade do código de segurança" />
            </FormRow>

            <InputMask
              mask="99999999999"
              name="cpf"
              type="text"
              id="docNumber"
              data-checkout="docNumber"
              placeholder="CPF"
            />

            <InputMask
              mask="(99)99999-9999"
              name="telephone"
              id="tel-input"
              placeholder="Telefone"
              type="text"
            />
            <Input name="email" id="email" placeholder="Email" type="text" />

            <Select
              placeholder="Parcelas"
              id="installments"
              name="installments"
            >
              {installmentsOptions.map(({ text, value }) => (
                <option key={`${text}${value}`} value={value}>
                  {text}
                </option>
              ))}
            </Select>

            <select
              id="docType"
              className="hidden"
              data-checkout="docType"
              disabled
            />

            <input
              type="hidden"
              id="creditCardNumber"
              value={creditCardNumber}
              data-checkout="cardNumber"
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />
            <input
              type="hidden"
              id="cardExpirationMonth"
              value={cardExpirationMonth}
              data-checkout="cardExpirationMonth"
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />
            <input
              type="hidden"
              id="cardExpirationYear"
              value={cardExpirationYear}
              data-checkout="cardExpirationYear"
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />

            <input
              value={inputPaymentMethodId}
              type="hidden"
              name="payment_method_id"
              id="payment_method_id"
            />

            <input
              type="hidden"
              name="tokenCart"
              id="tokenCart"
              value={token}
            />

            {inputPaymentToken && (
              <input name="token" type="hidden" value={inputPaymentToken} />
            )}

            <SubmitButton isSubmiting={isSubmiting} type="submit">
              {isSubmiting ? (
                <>
                  <LoadingBars>
                    <span />
                  </LoadingBars>
                </>
              ) : (
                <>Pagar</>
              )}
            </SubmitButton>
          </Form>
        </PaymentLayout>

        <OrderLayout
          items={paymentInfo.items}
          total={paymentInfo.formattedTotal}
        />
      </Layout>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default PagamentoMP;
