import styled from 'styled-components';

export const Container = styled.section`
  margin: 16px;
`;

export const OrderHeader = styled.div`
  display: flex;

  padding: 26px 16px;

  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 16px;
    line-height: 24px;
    color: #454443;

    @media (min-width: 700px) {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }
  }

  span {
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
  }
`;

export const Product = styled.div`
  display: flex;
  padding: 0 16px;
  color: #595857;

  align-items: center;

  & + div {
    margin-top: 8px;

    @media (min-width: 700px) {
      margin-top: 16px;
    }
  }

  img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }

  h4 {
    flex: 1;
    font-size: 14px;
    line-height: 21px;

    strong {
      font-weight: 700;
    }
  }

  span {
    margin-left: 10px;
    font-size: 16px;
    line-height: 24px;

    @media (min-width: 700px) {
      margin-left: 24px;
    }
  }
`;

export const Total = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 10px 16px;

  span {
    color: #454443;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;

    justify-self: right;
    align-items: center;

    @media (min-width: 700px) {
      font-size: 16px;
      line-height: 24px;
    }

    strong {
      font-size: 16px;
      line-height: 24px;

      @media (min-width: 700px) {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
`;
