/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useRef,
  useState,
  InputHTMLAttributes,
  useCallback,
  FocusEventHandler,
} from 'react';
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';
import { useField } from '@unform/core';

import Tooltip from '../Tooltip';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  'data-checkout'?: string;
}

interface InputMaskProps extends ReactInputMaskProps {
  name: string;
  'data-checkout'?: string;
}

const Input: React.FC<InputProps> = ({ name, onBlur, ...rest }) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  const [isFocused, setIsFocused] = useState(false);

  const checksFocus = useCallback(() => {
    clearError();
    setIsFocused(true);
  }, [clearError]);

  const checksBlur = useCallback(
    e => {
      setIsFocused(false);

      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFocused={isFocused}>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={checksFocus}
        onBlur={e => checksBlur(e)}
        {...rest}
      />

      {error && <Tooltip error={error} />}
    </Container>
  );
};

const InputMask: React.FC<InputMaskProps> = ({ name, ...rest }) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  const checksFocus = useCallback(() => {
    clearError();
    setIsFocused(true);
  }, [clearError]);

  const checksBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container isErrored={!!error} isFocused={isFocused}>
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={checksFocus}
        onBlur={checksBlur}
        {...rest}
      />

      {error && <Tooltip error={error} />}
    </Container>
  );
};

export default Input;
export { InputMask };
