import styled from 'styled-components';

export const Container = styled.footer`
  background-color: #1a1918;
  text-align: center;
  padding: 16px 32px;
  flex-shrink: 0;

  span {
    color: #eee;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
  }
`;
