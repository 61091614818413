import React from 'react';

import PageNotFoundImage from '../../assets/PageNotFound.svg';

import MessagePage from '../../components/MessagePage';

const PageNotFound: React.FC = () => {
  return (
    <MessagePage
      horizontal
      image={PageNotFoundImage}
      title="Página não encontrada"
      message="Infelizmente a página que você tentou acessar não foi encontrada, verifique se o link da página está correto e tente novamente."
    />
  );
};

export default PageNotFound;
