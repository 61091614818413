import styled, { keyframes, css } from 'styled-components';

interface SubmitButtonProps {
  isSubmiting: boolean;
}

export const Container = styled.div`
  display: flex;

  flex: 1;

  background-color: #f9fafc;
  padding: 30px 0;

  justify-content: center;
  align-items: center;
`;

export const Layout = styled.div`
  @media (min-width: 700px) {
    max-width: 1000px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;

    & > section {
      flex: 0 50%;
    }
  }
`;

export const PaymentLayout = styled.section`
  text-align: center;

  h2 {
    margin: 16px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }

  form {
    margin: 0 16px;

    .hidden {
      display: none;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;

  & + div {
    margin: 0;
  }

  & > div {
    margin: 0 5px 8px 0;
    padding: 8px 8px;
  }

  @media (min-width: 700px) {
    margin-bottom: 16px;
  }
`;

export const SubmitButton = styled.button<SubmitButtonProps>`
  width: 100%;
  height: 40px;
  margin-top: 16px;
  padding: 8px 32px;
  background-color: #ff9f1a;

  display: block;

  color: #f9fafc;
  font-weight: 700;
  font-size: 20px;

  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;

  ${props =>
    props.isSubmiting &&
    css`
      background-color: #ffb54d;

      &:hover {
        background-color: #ffb54d;
      }
    `}

  @media (min-width: 700px) {
    margin-top: 24px;
    width: 185px;
  }

  &:hover {
    background-color: #f58f00;
  }
`;

const growAnimation = keyframes`
  0% { transform: scaleY(0); opacity: 0;}
	50% { transform: scaleY(1); opacity: 1;}
	100% { transform: scaleY(0); opacity: 0;}
`;

export const LoadingBars = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 50px;
  vertical-align: middle;

  &:before,
  &:after,
  & span {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 20px;
    background-color: #fff;
    animation: ${growAnimation} 1.5s linear infinite;
  }

  &:after {
    left: 15px;
    animation: ${growAnimation} 1.5s linear -0.5s infinite;
  }

  & span {
    left: 30px;
    animation: ${growAnimation} 1.5s linear -1s infinite;
  }
`;

const errorAnimation = keyframes`
  from{
    font-size: 0px;
    padding: 0px 16px;
    line-height: 0px;
  }
  to {
    font-size: 12px;
    padding: 6px 16px;
    line-height: 17px;
  }
`;

export const Error = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 6px 16px;

  background-color: #ffd8db;
  border-radius: 4px;

  color: #f23524;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  animation: ${errorAnimation} 0.5s normal;
`;
