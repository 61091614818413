import styled from 'styled-components';
import { BiX } from 'react-icons/bi';
import logoMobile from '../../assets/logoMobile.png';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;

  padding: 24px;

  position: relative;
  z-index: 10;
  border-radius: 4px;
`;

export const ModalHeaderArea = styled.div`
  display: flex;
  flex: 0.2;
  justify-content: space-between;
  align-items: center;
`;

export const ModalContentArea = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: center;
  align-items: center;

  font-weight: 450;
  font-size: 14px;
  line-height: 21px;
  color: #141414;
  p {
    margin-bottom: 1rem;
    strong {
      font-weight: 700;
    }
  }
`;

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: center;
  flex: 0.2;

  button {
    cursor: pointer;
    padding: 8px 32px;
    background: #ff9f1a;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 4px;
  }
`;

export const Logo = styled.div`
  background: url(${logoMobile}) no-repeat;
  width: 36px;
  height: 29px;
`;

export const CloseModalButton = styled(BiX)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;
