import React from 'react';
import { OrderContentLoader } from '../ContentLoader';

import { Container, OrderHeader, Product, Total } from './styles';

interface Props {
  items: Product[];
  total: number;
}

export interface Product {
  id: number;
  descricao: string;
  cod_prod: number;
  preco: number;
  quant: number;
}

const OrderLayout: React.FC<Props> = ({ items, total }) => {
  return items ? (
    <Container>
      <OrderHeader>
        <h3>Resumo do pedido</h3>
        <span>
          N° de itens:
          {items &&
            ` ${items
              .map(item => item.quant)
              .reduce((previous, actual) => previous + actual)}`}
        </span>
      </OrderHeader>

      {items &&
        items.map(product => (
          <Product key={product.id}>
            <img
              src={`https://institucional.multicoisas.com.br/wp-images/site_produtos/${product.cod_prod}.jpg`}
              alt={product.descricao}
            />
            <h4>
              <strong>({product.quant})</strong>
              {product.descricao}
            </h4>
            <span>R${product.preco}</span>
          </Product>
        ))}

      <Total>
        <span>
          Total:
          <strong>{total && ` R$${total}`}</strong>
        </span>
      </Total>
    </Container>
  ) : (
    <OrderContentLoader />
  );
};

export default OrderLayout;
