import React from 'react';
import { BiXCircle } from 'react-icons/bi';

import { Container, Error } from './styles';

interface TooltipProps {
  error: string;
}

const Tooltip: React.FC<TooltipProps> = ({ error }) => {
  return (
    <Container>
      <BiXCircle size={20} color="#F23524" />
      <Error>{error}</Error>
    </Container>
  );
};

export default Tooltip;
