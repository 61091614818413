import { useCallback, useEffect, useState } from 'react';

interface WidthSize {
  widthSize: number;
}

const useWidthSize = (): WidthSize => {
  const [widthSize, setWidthSize] = useState(window.innerWidth);

  const updatePageWidth = useCallback(() => {
    if (widthSize !== window.innerWidth) {
      setWidthSize(window.innerWidth);
    }
  }, [widthSize]);

  useEffect(() => {
    window.addEventListener('resize', updatePageWidth);
    return () => window.removeEventListener('resize', updatePageWidth);
  }, [updatePageWidth]);

  return { widthSize };
};

export default useWidthSize;
