import React from 'react';

import InvalidTokenImage from '../../assets/InvalidToken.svg';

import MessagePage from '../../components/MessagePage';

const InvalidToken: React.FC = () => {
  return (
    <MessagePage
      horizontal
      image={InvalidTokenImage}
      title="Link de pagamento inválido"
      message="O link de pagamento se encontra inválido, entre em contato com a loja para obter uma outra forma de pagamento."
    />
  );
};

export default InvalidToken;
