import React from 'react';

import { Container, Layout, MessageSection, ImageSection } from './styles';

interface MessagePageProps {
  image: string;
  title: string;
  message?: string;
  horizontal?: boolean;
}

const MessagePage: React.FC<MessagePageProps> = ({
  image,
  title,
  message,
  horizontal,
}) => (
  <Container>
    <Layout isHorizontal={!!horizontal}>
      <ImageSection>
        <img src={image} alt="Sucesso!" />
      </ImageSection>

      <MessageSection>
        <h1>{title}</h1>
        <h4>{message}</h4>
      </MessageSection>
    </Layout>
  </Container>
);

export default MessagePage;
