import React from 'react';
import ContentLoader from 'react-content-loader';

import { OrderContainer } from './styles';

interface QRCodeProps {
  isDesktop: string;
}

const OrderContentLoader: React.FC = () => {
  return (
    <OrderContainer>
      <ContentLoader
        backgroundColor="#F9FAFC"
        foregroundColor="#FFEACD"
        style={{ width: '100%', height: '100%' }}
      >
        <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="100" rx="5" ry="5" width="70" height="70" />
        <rect x="80" y="117" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="140" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="200" rx="5" ry="5" width="70" height="70" />
        <rect x="80" y="217" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="240" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    </OrderContainer>
  );
};

const QRCodeContentLoader: React.FC<QRCodeProps> = ({ isDesktop }) => {
  return (
    <ContentLoader
      backgroundColor="#F9FAFC"
      foregroundColor="#FFEACD"
      style={{ width: isDesktop, height: isDesktop }}
    >
      <rect x="0" y="0" rx="0" ry="0" width={isDesktop} height={isDesktop} />
    </ContentLoader>
  );
};

export { OrderContentLoader, QRCodeContentLoader };
