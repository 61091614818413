import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PaymentFormMP from '../pages/PaymentFormMP';
import PaymentFormPS from '../pages/PaymentFormPS';
import PaymentPix from '../pages/PaymentPix';
import PaymentMessage from '../pages/PaymentMessage';
import InvalidToken from '../pages/InvalidToken';
import PageNotFound from '../pages/PageNotFound';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Routes: React.FC = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/pagamento/" exact component={PaymentMessage} />
        <Route path="/pagamento/mp/:token" component={PaymentFormMP} />
        <Route path="/pagamento/ps/:token" component={PaymentFormPS} />
        <Route path="/pagamento/pix/:token" component={PaymentPix} />
        <Route path="/invalid-token" component={InvalidToken} />
        <Route path="*" component={PageNotFound} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routes;
