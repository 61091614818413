import styled, { css, keyframes } from 'styled-components';

interface ReactSelectProps {
  isErrored: boolean;
}

export const StyledReactSelect = styled.select<ReactSelectProps>`
  width: 100%;
  height: 40px;
  padding: 8px 16px;

  display: flex;

  border: 1px solid #d8d9da;
  border-radius: 4px;

  appearance: none;
  background: #fff;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 4px),
    calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 0px 0px;
  background-repeat: no-repeat;
  color: #9e9d9b;

  ${props =>
    props.isErrored
      ? css`
          border-color: #f23524;
        `
      : css`
          border-color: #d8d9da;
        `}
`;

const errorAnimation = keyframes`
  from{
    font-size: 0px;
    padding: 0px 16px;
    line-height: 0px;
  }
  to {
    font-size: 12px;
    padding: 6px 16px;
    line-height: 17px;
  }
`;

export const Error = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: 6px 16px;

  background-color: #ffd8db;
  border-radius: 4px;

  color: #f23524;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  animation: ${errorAnimation} 0.5s normal;
`;
