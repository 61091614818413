/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, useCallback, useRef, useState } from 'react';

interface CopyToClipboardHook {
  inputRef: MutableRefObject<HTMLInputElement>;
  copySuccessMessage: string;
  copyToClipboard: (e: any) => void;
}

const useCopyToClipboard = (): CopyToClipboardHook => {
  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [copySuccessMessage, setCopySuccessMessage] = useState('');

  const copyToClipboard = useCallback(
    e => {
      inputRef.current.disabled = false;

      inputRef.current.select();
      document.execCommand('copy');

      inputRef.current.disabled = true;
      e.target.focus();

      setCopySuccessMessage('Chave Pix copiada com sucesso!');

      setTimeout(() => {
        setCopySuccessMessage('');
      }, 5000);
    },
    [inputRef],
  );

  return { inputRef, copySuccessMessage, copyToClipboard };
};

export default useCopyToClipboard;
