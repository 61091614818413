/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';

import api from '../../services/api';
import usePagSeguro from '../../hooks/pagSeguro';

import Modal from '../../components/Modal';
import Input, { InputMask } from '../../components/Input';
import Select from '../../components/Select';
import OrderLayout, { Product } from '../../components/OrderLayout';

import creditCard from '../../assets/creditCard.svg';

import getValidationError from '../../utils/getValidationError';

import {
  Container,
  Layout,
  PaymentLayout,
  FormRow,
  SubmitButton,
  LoadingBars,
  Error,
} from './styles';

declare global {
  interface Window {
    PagSeguroDirectPayment: any;
  }
}

interface ParamsProps {
  token: string;
}

interface InstallmentsOptionsData {
  text: string;
  value: string;
  installmentAmount: number;
}

interface PaymentInfoData {
  tokenSession: string;
  tokenCart: string;
  formatedTotal: number;
  total: number;
  items: Product[];
}

interface CardInfoData {
  name: string;
}

const PagamentoPS: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { token } = useParams<ParamsProps>();
  const history = useHistory();
  const { validationSchema } = usePagSeguro();

  const [showModal, setShowModal] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState({} as PaymentInfoData);
  const [cardInfo, setCardInfo] = useState({} as CardInfoData);
  const [cardExpirationMonth, setCardExpirationMonth] = useState('');
  const [cardExpirationYear, setCardExpirationYear] = useState('');
  const [inputSessionHash, setInputSessionHash] = useState('');
  const [inputCreditCardNumber, setInputCreditCardNumber] = useState('');
  const [inputInstallmentAmount, setInputInstallmentAmount] = useState('');
  const [error, setError] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [doSubmit, setDoSubmit] = useState(false);
  const [installmentsOptions, setInstallmentsOptions] = useState<
    InstallmentsOptionsData[]
  >([
    {
      text: 'Número de parcelas',
      value: 'initialValue',
      installmentAmount: 0,
    },
  ]);

  useEffect(() => {
    async function tokenValidator() {
      try {
        const result = await api.get(
          `/payment/pagSeguro/checkurl?token=${token}`,
        );

        const {
          tokenCart,
          tokenSession,
          formatedTotal,
          total,
          items,
        } = result.data.data;

        setPaymentInfo({
          tokenCart,
          tokenSession,
          formatedTotal,
          total,
          items,
        });
      } catch (err) {
        history.push('/invalid-token');
      }
    }
    tokenValidator();
  }, [history, token]);

  /*
    ------------------------------------------
    Configurações iniciais da SDK do PagSeguro
    ------------------------------------------
  */
  const PagSeguro = useCallback(() => {
    if (paymentInfo.tokenSession !== undefined) {
      window.PagSeguroDirectPayment.setSessionId(`${paymentInfo.tokenSession}`);
    }
  }, [paymentInfo.tokenSession]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js';
    script.async = true;
    script.onload = () => PagSeguro();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [PagSeguro]);

  /*
    ---------------------------------
    Obtenção de parcelas do PagSeguro
    ---------------------------------
  */
  const getInstallments = useCallback(
    cardBrand => {
      window.PagSeguroDirectPayment.getInstallments({
        amount: paymentInfo.total,
        maxInstallmentNoInterest: 4,
        brand: cardBrand,
        success(response: any) {
          setInstallmentsOptions([]);

          response.installments[cardBrand].forEach((installment: any) => {
            if (
              !installment.interestFree ||
              (installment.quantity > 1 && installment.installmentAmount < 10)
            )
              return;

            const installmentAmountFormated = installment.installmentAmount.toLocaleString(
              'pt-br',
              {
                minimumFractionDigits: 2,
              },
            );

            const installmentOption = {
              text: `${installment.quantity} parcela(s) de R$${installmentAmountFormated}`,
              value: installment.quantity,
              installmentAmount: installment.installmentAmount.toFixed(2),
            };

            setInstallmentsOptions(previousOptions => [
              ...previousOptions,
              installmentOption,
            ]);
          });

          setInputInstallmentAmount(
            response.installments[cardBrand][0].installmentAmount,
          );
        },
        error(response: any) {
          formRef.current?.setFieldError(
            'installments',
            `Erro nas parcelas ${response}`,
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        complete(response: any) {
          // retorno padrão
        },
      });
    },
    [paymentInfo.total],
  );

  const handleInstallmentsChange = useCallback(
    event => {
      const installmentAmountOption =
        installmentsOptions[event.target.value - 1].installmentAmount;

      setInputInstallmentAmount(`${installmentAmountOption}`);
    },
    [installmentsOptions],
  );

  /*
    ----------------------------------------
    Obtendo informações do cartão de crédito
    ----------------------------------------
  */

  const guessPaymentMethod = useCallback(
    event => {
      const cardMasked = event.target.value;
      const cardFormatted = cardMasked.replace(/\D/g, '');
      setInputCreditCardNumber(cardFormatted);

      if (cardFormatted.length > 6) {
        window.PagSeguroDirectPayment.getBrand({
          cardBin: cardFormatted,
          success(response: any) {
            setCardInfo(response.brand);
            getInstallments(response.brand.name);
          },
          error(response: any) {
            event.preventDefault();
            formRef.current?.setFieldError(
              'creditCardNumber',
              `Cartão inválido, ${response}`,
            );
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          complete(response: any) {
            // tratamento comum para todas chamadas
          },
        });
      }
    },
    [getInstallments],
  );

  const getSessionHash = useCallback(() => {
    // eslint-disable-next-line consistent-return
    window.PagSeguroDirectPayment.onSenderHashReady(function callbackFunction(
      response: any,
    ) {
      if (response.status === 'error') {
        setIsSubmiting(false);
        setError(`Erro para gerar o Session Hash, ${response.message}`);
        return false;
      }
      setInputSessionHash(response.senderHash);
    });
  }, []);

  /*
    ----------------------------
    Envio de dados do formulário
    ----------------------------
  */
  const formSubmit = useCallback(
    async (telephone, cardHash) => {
      try {
        const paymentData = {
          tokenCart: paymentInfo.tokenCart,
          installments: Number(formRef.current?.getFieldValue('installments')),
          sessionHash: inputSessionHash,
          cardHash,
          installmentAmount: Number(inputInstallmentAmount),
          amount: paymentInfo.total,
          cardHolderDocument: formRef.current?.getFieldValue('cpf'),
          cardHolderName: formRef.current?.getFieldValue('cardholderName'),
          cardHolderBirthDate: formRef.current?.getFieldValue(
            'cardHolderBirthDate',
          ),
          email: formRef.current?.getFieldValue('email'),
          telefone: telephone,
        };

        const { status } = await api.post('payment/pagSeguro', paymentData);

        history.push('/pagamento', {
          responseCode: status,
          SDK: 'PagSeguro',
        });
      } catch (err) {
        history.push('/pagamento', {
          responseCode: 500,
        });
      }
    },
    [
      history,
      inputInstallmentAmount,
      inputSessionHash,
      paymentInfo.tokenCart,
      paymentInfo.total,
    ],
  );

  const handleFormSuccess = useCallback(
    (response: any) => {
      const cardHash = response.card.token;

      setDoSubmit(true);

      const telephoneInput: string = formRef.current?.getFieldValue(
        'telephone',
      );

      let telephone = telephoneInput;

      if (telephoneInput[13] === '_') {
        telephone = telephoneInput.slice(0, 13);
      }

      formSubmit(telephone, cardHash);
    },
    [formSubmit],
  );

  // eslint-disable-next-line consistent-return
  const doPay = useCallback(() => {
    const expiryDate = formRef.current?.getFieldValue('expiryDate');

    setCardExpirationMonth(expiryDate.slice(0, 2));
    setCardExpirationYear(expiryDate.slice(3, 7));

    const expirationMonth = expiryDate.slice(0, 2);
    const expirationYear = expiryDate.slice(3, 7);

    if (!doSubmit) {
      window.PagSeguroDirectPayment.createCardToken({
        cardNumber: inputCreditCardNumber,
        brand: cardInfo.name, // Bandeira do cartão
        cvv: formRef.current?.getFieldValue('securityCode'),
        expirationMonth,
        expirationYear,
        success: handleFormSuccess,
        error(response: any) {
          setIsSubmiting(false);
          setError(`Preencha os campos corretamente, ${response.message}`);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        complete(response: any) {
          // Callback para todas chamadas.
        },
      });

      return false;
    }
  }, [cardInfo, doSubmit, handleFormSuccess, inputCreditCardNumber]);

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (data: object, { reset }, event) => {
      try {
        setIsSubmiting(true);
        formRef.current?.setErrors({});
        setError('');

        await validationSchema.validate(data, { abortEarly: false });

        doPay();
      } catch (err) {
        setIsSubmiting(false);
        if (err instanceof Yup.ValidationError) {
          const validationErrors = getValidationError(err);
          formRef.current?.setErrors(validationErrors);
        }
      }
    },
    [doPay, validationSchema],
  );

  return (
    <Container>
      <Layout>
        <PaymentLayout>
          {error && <Error>Erro no formulário: {error}</Error>}
          <h2>Formulário de Pagamento</h2>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="cardholderName"
              id="cardholderName"
              placeholder="Nome como está no cartão"
              type="text"
              onBlur={getSessionHash}
              data-checkout="cardholderName"
              autoComplete="off"
            />
            <InputMask
              mask="9999 9999 9999 9999"
              name="creditCardNumber"
              placeholder="Número do cartão"
              type="text"
              onInput={guessPaymentMethod}
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />
            <FormRow>
              <InputMask
                mask="99/9999"
                name="expiryDate"
                placeholder="Validade cartão"
                type="text"
                onSelect={e => e.preventDefault()}
                onPaste={e => e.preventDefault()}
                onCopy={e => e.preventDefault()}
                onCut={e => e.preventDefault()}
                onDrag={e => e.preventDefault()}
                onDrop={e => e.preventDefault()}
                autoComplete="off"
              />
              <InputMask
                mask="999"
                name="securityCode"
                placeholder="Cód. segurança"
                type="text"
                id="securityCode"
                data-checkout="securityCode"
                onSelect={e => e.preventDefault()}
                onPaste={e => e.preventDefault()}
                onCopy={e => e.preventDefault()}
                onCut={e => e.preventDefault()}
                onDrag={e => e.preventDefault()}
                onDrop={e => e.preventDefault()}
                autoComplete="off"
              />
              <img src={creditCard} alt="Localidade do código de segurança" />
            </FormRow>

            <InputMask
              mask="99999999999"
              name="cpf"
              type="text"
              id="docNumber"
              data-checkout="docNumber"
              placeholder="CPF"
            />

            <InputMask
              mask="99/99/9999"
              name="cardHolderBirthDate"
              type="text"
              placeholder="Data de Nascimento"
            />

            <InputMask
              mask="(99)99999-9999"
              name="telephone"
              id="tel-input"
              placeholder="Telefone"
              type="text"
            />
            <Input name="email" id="email" placeholder="Email" type="text" />

            <Select
              placeholder="Parcelas"
              id="installments"
              name="installments"
              onChange={handleInstallmentsChange}
            >
              {installmentsOptions.map(({ text, value, installmentAmount }) => (
                <option
                  key={`${text}${value}`}
                  data-installment-amount={installmentAmount}
                  value={value}
                >
                  {text}
                </option>
              ))}
            </Select>

            <input
              type="hidden"
              id="creditCardNumber"
              data-checkout="cardNumber"
              value={inputCreditCardNumber}
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />
            <input
              type="hidden"
              id="cardExpirationMonth"
              value={cardExpirationMonth}
              data-checkout="cardExpirationMonth"
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />
            <input
              type="hidden"
              id="cardExpirationYear"
              value={cardExpirationYear}
              data-checkout="cardExpirationYear"
              onSelect={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
              onCopy={e => e.preventDefault()}
              onCut={e => e.preventDefault()}
              onDrag={e => e.preventDefault()}
              onDrop={e => e.preventDefault()}
              autoComplete="off"
            />

            <input
              type="hidden"
              name="sessionHash"
              id="sessionHash"
              value={inputSessionHash}
            />

            <input
              type="hidden"
              value={inputInstallmentAmount}
              name="installmentAmount"
              id="installmentAmount"
            />

            <SubmitButton isSubmiting={isSubmiting} type="submit">
              {isSubmiting ? (
                <>
                  <LoadingBars>
                    <span />
                  </LoadingBars>
                </>
              ) : (
                <>Pagar</>
              )}
            </SubmitButton>
          </Form>
        </PaymentLayout>

        <OrderLayout
          items={paymentInfo.items}
          total={paymentInfo.formatedTotal}
        />
      </Layout>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default PagamentoPS;
